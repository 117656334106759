<template>
  <div class="home-container home-container">
    <Header></Header>
    <div class="container">
      <div class="first pr">
        <img src="../assets/img/home/home-banner.jpg" alt="" class="w-100c" />
        <div class="pa-center color-white text-center">
          <h1 class="fs-64">深如美设</h1>
          <h2 class="fs-64 mt-10">Sirimuch</h2>
        </div>
      </div>
      <div :style="{ height: clientHeight + 'px' }">
        <div style="background: #f9f9f9">
          <!-- 关于我们 start-->
          <div style="background: #fff" class="h-padding">
            <section class="max-w-1200 max-1400">
              <!-- <el-row :gutter="20" class="h-title">
              <b>A</b>
              <h4 class="t-left w-100">
                <span>About Us</span>
                <p>关于我们</p>
              </h4>
            </el-row> -->
              <el-row class="h-about" :gutter="20">
                <el-col :span="12">
                  <div class="h-title">
                    <b>ABOUT US</b>
                    <h4 class="t-left w-100">
                      <!-- <span>About Us</span> -->
                      <p>关于我们</p>
                      <i></i>
                    </h4>
                  </div>
                  <p>
                    深如美设（上海）供应链科技有限公司位于中国上海，是一家专业的货运代理公司。
                  </p>
                  <p>
                    基于多年的物流行业从业经历，以及对市场的深入研究、分析。推出危险品拼箱及进口FOB两大特色物流业务，
                    宗旨是为了满足市场现有的服务空缺,
                    提供差异化的物流服务理念，定位于精细化物流服务。<br />
                    深如美设（上海）供应链科技有限公司具有危险品货物EDI申报质资，
                    拥有配套的仓储分拨的硬件设施以及代理报关权的综合性物流企业。
                  </p>

                  <!-- <el-button style="margin-top: 10px"
                    ><a>了解更多</a></el-button
                  > -->
                </el-col>
                <el-col :span="12"
                  ><img src="../assets/img/home/about.png" style="width: 100%"
                /></el-col>
              </el-row>
            </section>
          </div>
          <div class="max-w-1200 h-padding max-1400">
            <section>
              <el-row :gutter="20" class="h-title">
                <b>Major Businesses</b>
                <h4 class="t-left w-100">
                  <p>主营业务</p>
                  <i></i>
                </h4>
              </el-row>
              <el-row class="hahaha max-w-1200 home_serve">
                <el-col :span="8" :gutter="10">
                  <ul>
                    <li>
                      <img src="../assets/img/home/cb1.jpg" />
                    </li>
                    <li>
                      <h5>海运服务</h5>
                    </li>
                  </ul>
                </el-col>
                <el-col :span="8">
                  <ul>
                    <li>
                      <img src="../assets/img/home/cb2.jpg" />
                    </li>
                    <li>
                      <h5>空运服务</h5>
                    </li>
                  </ul>
                </el-col>
                <el-col :span="8">
                  <ul>
                    <li>
                      <img src="../assets/img/home/cb4.jpg" />
                    </li>
                    <li>
                      <h5>仓储配送</h5>
                    </li>
                  </ul>
                </el-col>
                <div class="crear"></div>
              </el-row>
            </section>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Home",
  data() {
    return {
      activeName: "first",
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {},
  methods: {},
};
</script>
